import Banner from "../../components/Banner/Banner";
import HeroSmall from "../../components/HeroSmall/HeroSmall";
import "./LeadershipPage.scss";
import marck from "../../assets/images/Marck.jpg";
import bill from "../../assets/images/billHeadshot.jpg";
import jackson from "../../assets/images/jacksonHeadshot.jpg";
import graceBird from "../../assets/images/whitegracelogo.png";
import michelle from "../../assets/images/michelle.jpg";

export default function LeadershipPage() {
  return (
    <div className="leadership">
      <HeroSmall content="staff & leadership" classtitle="heroSmall--staff" />
      <Banner />
      <section className="leadership__pastor">
        <div className="pastor__container">
          <img
            src={jackson}
            alt="Headshot of Jackson Watts"
            className="pastor__img"
          />
        </div>
        <div className="staff__content">
          <h5 className="staff__title">senior pastor</h5>
          <h2 className="staff__name">Jackson Watts</h2>
          <div className="pastor__container pastor__container--mobile">
            <img
              src={jackson}
              alt="Headshot of Jackson Watts"
              className="pastor__img"
            />
          </div>
          <p className="staff__paragraph">
            Jackson Watts began pastoring Grace in August 2011, after
            ministering in North Carolina.
          </p>
          <p className="staff__paragraph">
            Jackson grew up in South Carolina, and was called to preach shortly
            after beginning studies at Welch College. He was ordained shortly
            after graduation, then served from 2007-2011 as Minister of
            Education at Tippett's Chapel FWB in Clayton, North Carolina. While
            there he earned graduate degrees from Southeastern Baptist
            Theological Seminary and Duke Divinity School. He has since
            completed a Ph.D. from Concordia Seminary.
          </p>
          <p className="staff__paragraph">
            In July of 2011, Jackson was called to serve as pastor in Grace's
            50th year of ministry. He and his wife Mckensie met in North
            Carolina, and she joined him in Missouri after the two were wed in
            2012. They now reside in Pevely with their two children, Amos and
            Isla.
          </p>
        </div>
      </section>
      <section className="leadership__music">
        <div className="staff__content">
          <h5 className="staff__title">music minister</h5>
          <h2 className="staff__name">Bill Weeks</h2>
          <img
            src={bill}
            alt="Headshot of Bill Weeks"
            className="music__img music__img--mobile"
          />

          <p className="staff__paragraph">
            Bill began volunteering as our Music Minister after stepping into
            the role in an interim capacity in January of 2024. Bill and his
            wife Michelle have a long history with Grace, and have many years of
            local church ministry experience. Bill is a graduate of Washington
            University (B.S., B.S.) and the University of Illinois at
            Urbana-Champaign (M.S., Ph.D.). He works full-time in quant
            investing.
          </p>
        </div>
        <img src={bill} alt="Headshot of Bill Weeks" className="music__img" />
      </section>
      <section className="leadership__support">
        <div className="support">
          <section className="support__img">
            <img
              src={michelle}
              alt="Headshot of Michelle Weeks"
              className="support__michelle"
            />
          </section>
          <h5 className="support__title">Office Administrator</h5>
          <h4 className="support__name support__name--diana">Michelle Weeks</h4>
          <p className="support__paragraph">
            Michelle came on staff in December of 2024. She has an extensive
            background in education. She is a graduate of Southeast Missouri
            State University (B.S.) and the University of Illinois at
            Urbana-Champaign (M.S.). She is married to Bill, with whom she has
            three children. She was born and raised at Grace.
          </p>
        </div>
        <div className="support">
          <section className="support__img">
            <img
              src={marck}
              alt="Headshot of Marck Funston"
              className="support__marck"
            />
          </section>
          <h5 className="support__title">Custodian</h5>
          <h4 className="support__name support__name--marck">Marck Funston</h4>
          <p className="support__paragraph">
            Marck has served as Custodian since September 2017. He also
            volunteers as a Trustee. He retired after many years of work with
            AmeriGas. Marck and his wife Bonnie have two sons, three
            grandchildren. They’ve been members of Grace for over 40 years.
          </p>
        </div>
      </section>
      <section className="leadership__deacons">
        <h3 className="deacons__heading">deacons</h3>
        <div className="deacons__wrapper">
          <section className="deacon">
            <div className="deacon__container">
              <img
                src={graceBird}
                alt="White Grace Dove"
                className="deacon__img"
              />
            </div>
            <h4 className="deacon__name">Terry Wiedner</h4>
          </section>
          <section className="deacon">
            <div className="deacon__container">
              <img
                src={graceBird}
                alt="White Grace Dove"
                className="deacon__img"
              />
            </div>
            <h4 className="deacon__name">Les Bowling</h4>
          </section>
          <section className="deacon">
            <div className="deacon__container">
              <img
                src={graceBird}
                alt="White Grace Dove"
                className="deacon__img"
              />
            </div>
            <h4 className="deacon__name">Ron Lowe</h4>
          </section>
          <section className="deacon">
            <div className="deacon__container">
              <img
                src={graceBird}
                alt="White Grace Dove"
                className="deacon__img"
              />
            </div>
            <h4 className="deacon__name">Mike Shoults</h4>
          </section>
        </div>
      </section>
    </div>
  );
}
